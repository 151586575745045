
  .cardEmailSub {
    display: block;
    position: relative;
    width: 480px;
    max-width: 90%;
    min-height: 12em;
    background: #A50113;
    color: #ffffff;
    padding: 1em 2em 2em;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.3);
    text-align: center;
  }
  
  .cardEmailSub form {
    display: flex;
    flex-flow: column wrap;
    align-items: center;
  }
  
  .cardEmailSub form input {
    display: flex;
    width: 80%;
  }
  
  .cardEmailSub form input.email {
    border: 0px;
    margin-bottom: 1em;
    padding: .3em 1em;
    text-align: center;
    font-size: 24px;
    line-height: 30px;
    outline: none !important;
    border-radius: 5px;
    background-color: #FFFBF2;
  }
  
  .cardEmailSub form button {
    border: 0px transparent;
    padding: 1em 1.2em;
    border-radius: 5px;
    background-color: #282c34;
    color: #FFFBF2;
    font-size: 18px;
    letter-spacing: 0;
    font-weight: 700;
    text-transform: uppercase;
    transition: .4s all ease-in-out;
    cursor: pointer;
  }
  
  .cardEmailSub form button:hover { opacity: 0.5; }