/* original colours comment these out uncomment ones below */
a{
    color: #A50113;
}

.colorNav {
    background-color: #FFFBF2;
  }
  
  .btn-primary {
    background-color: #A50113;
    border-color: #A50113;
    font-size: larger;
  }
  .btn-primary:hover {
    background-color: #A50113;
  }
  .dropdown-menu{
    color: #FFFBF2;
    background: #FFFBF2;
  }
  .dropdown-item{
    color: #A50113;
    font-weight: bold;
  }
  /** colour to try its not perfect I know  */
  
  /*.colorNav {
    background-color: #ef0000;
  }
  .container-fluid .btn{
    background-color: #ef0000 !important;
    border-color: #ef0000;
    text-shadow: 2px 2px black;
    font-size: larger !important;
    font-weight: bolder !important;
  }*/
  
  .popFooter {
    display: none;
  }
  
  img.logoMobile {
    width: 70%;
    padding-left: 10px;
  }
  
  .columnNav {
    float: left;
    width: 240px;
    padding: 0 0.5%;
  }
  
  i.navBar {
    text-align: center;
    font-size: small;
    color: black;
    letter-spacing: 0rem;
    margin-right: auto;
    margin-left: auto;
  }
  
  @media screen and (max-width: 991px) {
    .popFooter {
      display: flex;
      position: fixed;
      bottom: 0;
      width: 100%;
      margin-bottom: 10px;
    }
  }
  
  .rowFooter {
    width: 100%;
  }
  