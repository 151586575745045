.AboutMe{
    background: #EAE7E2;
    color: #7991AB;
    display: grid;
    padding: 2rem;
    grid-template-columns: 300px 1fr;
    gap: 1rem;
    align-items: center;
    max-width: 100%;
    margin: 0 auto;
    font: 500 100%/1.5 system-ui;
}

img.AboutMe{
    max-width: 100%;
    height: auto;
}


  #header .header_adjust {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
}

#header .left{
    width:22.5%;
    float:left;
    margin-bottom: 10px;
/*      background-color: red;*/
}
    #header .left img{
        margin:0%;
        max-width:100%;
        max-height:100%;
        -moz-border-radius:5px 20px 5px; -webkit-border-radius:5px 20px 5px; border-radius:5px 20px 5px;
    }
#header .right{
    width:79s.95833335%;
    max-height:100%;
    min-height:100%;
    margin:0% 1%;
    /*background-color: red;*/
    background: #7991AB; 
    background-color: #7991AB;
    background-size: 20px 20px;
    -moz-border-radius:5px;
    -webkit-border-radius:5px;
    border-radius:5px;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    float:right;
}

@media screen 
and (max-width: 480px){ 
#header .header_adjust{
    width:98%;
  display: block;       
}

#header .header_adjust .left {

    width:100%;
    height: auto;
    margin:0%;
    background-color:red;
}
    #header .left img{
        margin:0%;
        width:100%;
        height:auto;

    }

#header .header_adjust .right {
    float:none;
    width:100%;
    height:auto;
    clear:both;
    margin:0%;
    background-color:black;
}
}


h2.AboutMeHeader{
    color: #EAE7E2;
}
p.AboutMeText{    
    color: #EAE7E2;
}