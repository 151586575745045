.HeaderDiv{
  background: #FFFBF2;
  color: #FFFBF2;
}
h1.Header{
  margin-bottom: 0rem;
  color:#A50113;
}
a.Header{
    text-decoration: none;
}
a.Header:hover{
  color: #fff;
  opacity: 0.5;
}