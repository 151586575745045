.SpacingDivs{
    padding-bottom: 50px;
}

.Blue{
    background-color: #7991AB;
    height: 550px;
    padding-top: 5%;
    margin-top: 5%;
}
.podcastDiv{
    margin-top: 50px;
}