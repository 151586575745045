.parallax{
    background-image: url("https://media1.giphy.com/media/3oz8xur099boo4N9aU/giphy.gif");
    min-height: 500px; 
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin: 0;
}

#parallaxHeader {
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #EAE7E2;
}
#parallaxText {
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #EAE7E2;
  }
  #parallaxSubheading {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #EAE7E2;
  }

.text-box{
	height: 600px;
	padding: 50px;
}
@media only screen and (max-device-width: 1024px) {
    .parallax {
        background-attachment: scroll;
    }
}

.navbar {
    margin-bottom: 0 !important;
}