.card-body{
    padding: 0px;
}
.cardTitle{
    color: #A50113;
}
.cardText{
    color: #010A10;
    text-decoration: none;
}
a.cardText {
    text-decoration: none;
}
